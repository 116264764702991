ul > li {
    margin-bottom: 0.5rem;
    text-align: start;
    text-wrap: nowrap;
    display: flex;
    align-items: center;
}

ul > li:first-child {
    margin-top: 0.5rem;
}