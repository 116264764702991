body {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select:none;
}
.App {
    overflow: hidden;
    height: 100vh;
    font-weight: bolder;
}

 /*============ scrollbar============ */

 /* 寬度 */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /* display: none; */
}

/* 背景 */
::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}

/* 把手】*/
::-webkit-scrollbar-thumb {
    background: #828282;
}

/* hover */
::-webkit-scrollbar-thumb:hover {
    background: #454545;
}

.testScrollbarNone::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    /* display: none; */
}

.custom-popover {
    z-index: 9999; /* 设置一个较大的 z-index 值 */
    position: fixed;
}