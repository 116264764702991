.slick-arrow {
    position: absolute;
    display: none !important;
}

.slick-dots {
    display: none !important;
}

.slick-track {
    display: flex;
}
.slick-slide {
    margin: auto;
}